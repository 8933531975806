<template>
	<PopupNotification ref="notification"/>

    <div class="archives">
        <table id="users">
			<tr>
				<th class="text"> Numéro Everwin </th>
				<th class="text"> Prénom </th>
				<th class="text"> Statut du CV </th>
				<th class="text"> Dates </th>
				<th class="text"> Date d'archivage</th>
				<th class="text"></th>
			</tr>
			<tr class="text" v-for="(user, index) in userList" :key="index" style="border-bottom: 2px solid #ddd; line-height: 20px;">
				<td class="text">{{ user.everwinNum }}</td>
				<td class="text">{{ user.firstName }}</td>
				<td class="text" style="font-weight: bold;color: maroon;">Archivé</td>
				<td class="text" style="font-size: 13px;">Créé le <strong>{{ user.creationDate }}</strong><br>Mise à jour du CV le <strong>{{ user.lastResumeUpdate }}</strong></td>
				<td class="text" style="height: 40px;">Archivé le <strong>{{ user.archivedDate }}</strong></td>
				<td class="btn_manage_users" style="height: 40px;">
					<button class="btn_user"><img class="svg_btn_user" src="@/assets/images/download.svg" style="width: 1.5rem;" @click="downloadResume(user.userId, user.firstName, user.everwinNum)" title="Télécharger le cv de l'utilisateur"/></button>
					<button class="btn_user"><img class="svg_btn_user" src="@/assets/images/bin.svg" style="width: 1.5rem;" @click="deleteUser(user.userId)" title="Supprimer l'utilisateur"/></button>
				</td>
			</tr>
		</table>
    </div>

	<!--		SUPPRIMER UTILISATEUR			-->
	<div v-if="isDeleteModalVisible" class="modal">
		<div class="modal-content" style="width: 40%;">
			<h2 class="text" style="margin: 30px;">Supprimer un utilisateur</h2>
			<div class="text" style="margin: 30px;">Êtes vous sur de vouloir supprimer l'utilisateur ?</div>
			<div class="text" style="margin: 30px; color: red;">Cette action est irréversible !</div>
			<button class="orange_button" style="text-wrap: nowrap; padding: 10px; margin-right: 1rem" @click="confirmDelete()">Confirmer</button>
			<button class="cancel-button" @click="isDeleteModalVisible=false">Annuler</button>
		</div>
	</div>

</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import {BACK_URL} from '@/utils/ip'

export default {
    name: 'ArchivesTab',
	props: ['searchInputProp'],
    data() {
        return {
            userList: [
				{userId: "", firstname: "", everwinNum: "", role: "", creationDate:"", lastResumeUpdate: "", archivedDate: ""},
			],
			allArchives: [
				
			],
			selectedUser: {userId: "", firstname: "", everwinNum: "", role: "", archivedDate: ""},
			searchInput: this.searchInputProp,
			selectedFilter: "Tous",

			isDeleteModalVisible: false,	// popup de confirmation de suppression d'utilisateur
			userToDelete: "",				// id de l'utilisateur a supprimer
        };
    },
	components: {
		PopupNotification
	},
	computed: {
		...mapGetters({
            user: 'getUserData',
        })
	},
	mounted() {
		// Get all users on mounted
		this.getAll();
	},
	methods: {
		/**
		 * Fetch all users from the database
		*/
		getAll() {
			axios.get(`${BACK_URL}/api/archives`, {headers: {'Authorization': `${this.user.id}` }})
			.then(response => {
				this.allArchives = response.data.archives;
				console.log(this.allArchives)
				for (let i = 0; i < this.allArchives.length; i++) {
					const creationDate = new Date(this.allArchives[i].creationDate);
					const lastResumeUpdate = new Date(this.allArchives[i].lastResumeUpdate);
					const archivedDate = new Date(this.allArchives[i].archivedDate);

					// Formatter la date
					const optionsDate = { year: '2-digit', month: '2-digit', day: '2-digit' };
					// Formatter l'heure
					const optionsTime = { hour: '2-digit', minute: '2-digit' };

					this.allArchives[i].creationDate = creationDate.toLocaleDateString('fr-FR', optionsDate) + " à " + creationDate.toLocaleTimeString('fr-FR', optionsTime).replace(':', 'h');
					this.allArchives[i].lastResumeUpdate = lastResumeUpdate.toLocaleDateString('fr-FR', optionsDate) + " à " + lastResumeUpdate.toLocaleTimeString('fr-FR', optionsTime).replace(':', 'h');
					this.allArchives[i].archivedDate = archivedDate.toLocaleDateString('fr-FR', optionsDate) + " à " + archivedDate.toLocaleTimeString('fr-FR', optionsTime).replace(':', 'h');
				}

				this.userList = response.data.archives;
			})
			.catch((error) => {
				console.error('Error fetching users:', error)
				this.$refs.notification.addNotification("Une erreur est survenue lors du chargement des utilisateurs. Veuillez recharger la page.", "error", 3000);
			});
		},

		/**
		 * Download the resume of an user
		 * @param {String} userId - The id of the user
		 * @param {String} firstName - The first name of the user
		 * @param {String} everwinNum - The everwin number of the user
		*/
		downloadResume(userId, firstName, everwinNum) {
			this.$refs.notification.addNotification("Votre téléchargement va commencer...", 'info', 5000);
			axios.post(`${BACK_URL}/api/download_resume`, {
				userId: userId,
				initiator: localStorage.me,
				fromArchives: true
			}, {
				responseType: 'blob'
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				if (response.data.type == "application/zip")
					link.setAttribute('download', 'CV ' +firstName + ' ' + everwinNum + '.zip');
				else
				link.setAttribute('download', 'CV ' +firstName + ' ' + everwinNum + '.pdf');
				document.body.appendChild(link);
				link.click();
			}).catch((error) => {
				console.error(error);
				this.$refs.notification.addNotification("Erreur lors du telechargement du CV. " + error, "error", 3000);
			});
		},

		/**
		 * Delete an user
		 * @param {String} userId - The id of the user to delete
		*/
		deleteUser(userId) {
			this.userToDelete = userId;
			this.isDeleteModalVisible = true;
		},
		/**
		 * Confirm the deletion of an user
		*/
		confirmDelete() {
			console.log("Deleting user with id: ", this.userToDelete, " by admin: ", this.user.id);
			axios.post(`${BACK_URL}/api/delete_archived_user_admin`, {userId: this.userToDelete, adminId: this.user.id})
			.then(() => {
				this.getAll();
				this.isDeleteModalVisible = false;
				this.userToDelete = "";
				this.$refs.notification.addNotification("Utilisateur supprimé.", "success", 3000);
			}).catch((error) => {
				console.log(error);
				this.$refs.notification.addNotification(error.response.data.message, "error", 3000);
			})
			this.isDeleteModalVisible = false;
		},

		/**
		 * Filter users by search input and selected filter
		*/
		filter_users() {
			try {
				this.userList = [];

				this.allArchives.forEach(user => {
					if (user.everwinNum.includes(this.searchInput) || user.firstName.includes(this.searchInput)) {
							this.userList.push(user);
						}
					}
				);
			} catch (e) {console.log(e)}	
		},
	},

	watch: {
		searchInputProp: {
			deep: true,
			handler() {
				this.searchInput = this.searchInputProp;
				this.filter_users();
			}
		}
	}
};
</script>

<style scoped>
.archives {
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}
</style>