export const SkillsOptions = [
    "Languages",
    "Logiciel & outils",
    "Systèmes",
    "Compétences",
    "Soft skills",
    "Autres"
];

export const TasksOptions = [
    "Autre",
    "Rédaction d'un cahier des charges",
    "Rédaction d'une analyse fonctionelle",
    "Chiffrage et estimation de temps",
    "Etude de l'architecture déjà existante",
    "Propositions de solutions aux différents besoins",
    "Développements [language]",
    "Développement d'une IHM",
    "Développement d'évolutions",
    "Développement de tests unitaires",
    "Montage d'une base de donnée",
    "Tests et déboggage",
    "Sécurisation [de mots de passes; d'une API; d'accès utilisateurs]",
    "Mise en service",
    "Mise en production",
    "Montage d'une architecture réseau",
    "Rédaction d'une documentation",
    "Rédaction d'un manuel utilisateur",
    "Formation du client",
    "Suivi de projet et support technique"
]
