<template>
	<PopupNotification ref="notification"/>

	<div :class="{ 'blur-background': isModalVisible || isArchiveModalVisible }">
		<div class="title text" style="
		position: relative;
		padding-left: 0;
		left: 5%;">
			Gestion des utilisateurs
		</div>
		<hr/>
		<div class="admin_panel">
			<div class="side_by_side_container" style="width: 90%;margin: auto;flex-wrap: nowrap">
				<div class="input-wrapper">
					<span class="label text" style="margin: 0;">
						<img src="@/assets/images/loupe.svg" style="width: 18px;"/>
						</span>
					<input type="text" v-model="searchInput" @input="filter_users()" style="padding-left: 20px;">
					<div class="text" style="margin-left: 10px;display: flex;align-items: center;">
						Filtrer par:
						<div class="select-dropdown" style="margin-left: 5px;">
							<select class="selector" style="width: 8rem;" @change="filter_users()" v-model="selectedFilter">
								<option v-for="(filter, index) in this.filters" :key="index" :selected="filter.isSelected">{{ filter.label }}</option>
							</select>
						</div>
					</div>
					<div class="text" style="margin-left: 20px;display: flex;align-items: center;">
						Trier par:
						<div class="select-dropdown" style="margin-left: 5px;">
							<select class="selector" style="width: 9rem;" @change="filter_users()" v-model="selectedSort">
								<option v-for="(sorter, index) in this.sorters" :key="index" :selected="sorter.isSelected">{{ sorter.label }}</option>
							</select>
						</div>
					</div>
				</div>

				<input v-if="this.isUserSuperAdmin" type="button" class="orange_button" style="text-wrap: nowrap; padding: 10px; margin-right: 10px;" @click="isArchivedView=!isArchivedView" :value="!isArchivedView ? 'Afficher les archives' : 'Afficher les utilisateurs'" />
				<button class="orange_button" style="text-wrap: nowrap; padding: 10px;" @click="isModalVisible = true; addUser=true">+ ajouter un utilisateur</button>
			</div>
			<table id="users" v-if="!isArchivedView">
				<tr>
					<th class="text"> Numéro Everwin </th>
					<th class="text"> Prénom </th>
					<th class="text"> Role </th>
					<th class="text"> Statut du CV </th>
					<th class="text"> Dates </th>
					<th class="text"></th>
				</tr>
				<tr class="text" v-for="(user, index) in userList" :key="index" style="border-bottom: 2px solid #ddd; line-height: 20px;">
					<td class="text" @click="modifyUser(user)">{{ user.everwinNum }}</td>
					<td class="text" @click="modifyUser(user)">{{ user.firstName }}</td>
					<td class="text" @click="modifyUser(user)">{{ user.role }}</td>
					<td class="text"
						style="font-weight: bold;"
						:style="{ color: `${user.resumeStatus == 'En cours' ? 'red' : user.resumeStatus == 'Validé' ? 'orange' : 'mediumseagreen'}`}">
						{{ user.resumeStatus }}
						<!-- Change status -->
						<button class="btn_user"><img class="svg_btn_user" src="@/assets/images/pen.svg" style="width: 1.5rem;" @click="changeResumeStatus(user, index)" title="Changer le statut du CV"/></button>
					</td>
					<td class="text" @click="modifyUser(user)" style="font-size: 13px;">Créé le <strong>{{ user.creationDate }}</strong><br>Mise à jour du CV le <strong>{{ user.lastResumeUpdate }}</strong></td>
					<td class="btn_manage_users" style="height: 40px;">
						<button class="btn_user" :style="{visibility: `${user.mailSent ? 'hidden' : 'visible'}`}"><img class="svg_btn_user" src="@/assets/images/envelope.svg" style="width: 1.5rem;" @click="isSendMailModalVisible = true; this.selectedUser = user" title="Envoyer le mail de création de compte"/></button>
						<button class="btn_user"><img class="svg_btn_user" src="@/assets/images/download.svg" style="width: 1.5rem;" @click="downloadResume(user.userId, user.firstName, user.everwinNum)" title="Télécharger le cv de l'utilisateur"/></button>
						<button class="btn_user"><img class="svg_btn_user" src="@/assets/images/pen.svg" style="width: 1.5rem;" @click="modifyUserCV(user)" title="Modifier le CV de l'utilisateur"/></button>
						<button v-if="user.firstName != 'Admin'" class="btn_user"><img class="svg_btn_user" src="@/assets/images/password-lock.svg" style="width: 1.5rem;" @click="isRenewPassordModalVisible = true; this.selectedUser = user" title="Envoyer un email de reinitialisation de mot de passe"/></button>
						<button v-if="user.firstName != 'Admin'" class="btn_user"><img class="svg_btn_user" src="@/assets/images/inbox-full.svg" style="width: 1.5rem;" @click="archiveUser(user.userId)" title="Archiver l'utilisateur"/></button>
						<!--<button class="btn_user"><img class="svg_btn_user" src="@/assets/images/bin.svg" style="width: 1.5rem;" @click="deleteUser(user.userId)" title="Supprimer l'utilisateur"/></button>-->
					</td>
				</tr>
			</table>
			<ArchivesTab v-else :search-input-prop="this.searchInput"/>
		</div>
	</div>

	<!--		AJOUTER / MODIFIER UTILISATEUR			-->
	<div v-if="isModalVisible" class="modal">
		<div class="modal-content" style="width: 40%;">
			<h2 class="text" style="margin: 30px;">{{ addUser ? 'Ajouter un utilisateur' : 'Modifier un utilisateur' }}</h2>
			<div class="form-group" v-for="(field, index) in formFields" :key="index" style="text-align: -webkit-center;">
				<div class="input-wrapper" style="width: 30%;">
					<span class="input_circle"></span>
					<input :type="field.type" :id="field.id" v-model="field.value" :placeholder="field.label" style="width: 100%;" required />
				</div>
			</div>
			<div class="form-group">
				<select style="width: 8rem;" v-model="role">
					<option v-for="(role, index) in this.roles" :key="index" :selected="role.toLowerCase() == this.selectedUser.role.toLowerCase()" >
						{{ role }}
					</option>
				</select>
			</div>
			<div>
                <button v-if="addUser" class="orange_button" style="text-wrap: nowrap; padding: 10px; margin-right: 1rem" @click="createUser()">Ajouter</button>
                <button v-else class="orange_button" style="text-wrap: nowrap; padding: 10px; margin-right: 1rem" @click="updateUser()">Confirmer</button>
                <button v-if="addUser" @click="cancel" class="cancel-button">Annuler</button>
                <button v-else @click="cancel" class="cancel-button">Fermer</button>
            </div>
		</div>
	</div>

	<!--			ARCHIVER UTILISATEUR			-->
	<div v-if="isArchiveModalVisible" class="modal">
		<div class="modal-content" style="width: 40%;">
			<h2 class="text" style="margin: 30px;">Archiver un utilisateur</h2>
			<div class="text" style="margin: 30px;">Êtes vous sur de vouloir archiver l'utilisateur ?</div>
			<div class="text" style="margin: 30px; color: red;">Cette action est irréversible !</div>
			<button class="orange_button" style="text-wrap: nowrap; padding: 10px; margin-right: 1rem" @click="confirmArchiveUser()">Confirmer</button>
			<button class="cancel-button" @click="cancel">Annuler</button>
		</div>
	</div>

	<!--		RENOUVELLER LE MOT DE PASSE			-->
	<div v-if="isRenewPassordModalVisible" class="modal">
		<div class="modal-content" style="width: 40%;">
			<h2 class="text" style="margin: 30px;">Changer le mot de passe</h2>
			<div class="text" style="margin: 30px;">Un mail permettant de réinitialiser le mot de passe sera envoyé à l'adresse suivante:</div>
			<div class="form-group">
			<div class="input-wrapper" style="width: 30%;">
					<span class="input_circle"></span>
					<span class="label text required" :class="{ 'hidden': true }">Adresse email</span>
					<input type="email" id="resetPasswordInput" name="email" style="width: 100%;" required />
				</div>
			</div>
			<button class="orange_button" style="text-wrap: nowrap; padding: 10px; margin-right: 1rem" @click="resetPassword()">Envoyer</button>
			<button class="cancel-button" @click="cancel">Annuler</button>
		</div>
	</div>

	<!--		ENVOIE MAIL			-->
	<div v-if="isSendMailModalVisible" class="modal">
		<div class="modal-content" style="width: 40%;">
			<h2 class="text" style="margin: 30px;">Envoyer les accès a l'utilisateur</h2>
			<div class="text" style="margin: 30px;">Entrez l'email de l'utilisateur afin qu'il reçoive ses identifiants:</div>
			<div class="form-group">
			<div class="input-wrapper" style="width: 30%;">
					<span class="input_circle"></span>
					<span class="label text required" :class="{ 'hidden': true }">Adresse email</span>
					<input type="email" id="sendMailInput" name="email" style="width: 100%;" required />
				</div>
			</div>
			<button class="orange_button" style="text-wrap: nowrap; padding: 10px; margin-right: 1rem" @click="sendAccountMail()">Envoyer</button>
			<button class="cancel-button" @click="cancel">Annuler</button>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import {BACK_URL} from '@/utils/ip'
import { convertResumeStatus } from '@/utils/enums/resumeStatus';
import ArchivesTab from './Archives.vue';

export default {
	name: "AdminForm",
	data() {
		return {
			panel: "view",
			formFields: [
				{ name: "prenom", type: "prenom", id: "prenom", value: "", label: "Prénom", isLabelHidden: false },
				{ name: "everwinNum", type: "everwinNum", id: "everwinNum", value: "", label: "Numéro Everwin", isLabelHidden: false },
				{ name: "email", type: "email", id: "email", value: "", label: "Adresse e-mail", isLabelHidden: false },
			],
			filters: [
				{ label: 'Tous', isSelected: true },
				{ label: 'Candidat', isSelected: false },
				{ label: 'Collaborateur', isSelected: false },
				{ label: 'Admin', isSelected: false },
				{ label: 'Super Admin', isSelected: false },
			],
			sorters: [
				{ label: 'Màj récente', isSelected: true, value: "recent" },
				{ label: 'Màj ancienne', isSelected: false, value: "old" },
				{ label: 'Numéro Everwin', isSelected: false, value: "everwin" },
				{ label: 'Prénom', isSelected: false, value: "firstname" },
				{ label: 'Role', isSelected: false, value: "role" },
				{ label: 'Statut', isSelected: false, value: "status" },
			],
			role: "",
			roles: [
				"Candidat", 
				"Collaborateur", 
				"Admin",
				"Super Admin"
			],
			userList: [
				{userId: "", firstName: "", everwinNum: "", role: "", resumeStatus: "", creationDate:"", lastResumeUpdate: "", emailSent: false},
			],
			allUsers: [
				
			],
			selectedUser: {userId: "", firstName: "", everwinNum: "", role: "", resumeStatus: ""},
			searchInput: "",
			selectedFilter: "Tous",
			selectedSort: "Màj récente",
			isUserSuperAdmin: false,		// true : l'utilisateur est super admin, false : l'utilisateur n'est pas super admin
			isModalVisible: false,			// popup de modification / ajout d'utilisateur
			isArchiveModalVisible: false,	// popup de confirmation de suppression d'utilisateur
			isRenewPassordModalVisible: false,	// popup de renvoie d'un MDP
			isSendMailModalVisible: false,	// popup d'envoie d'un mail
			isArchivedView: false,			// true : on affiche les utilisateurs archivés, false : on affiche les utilisateurs actifs
			addUser: false,					// true : on ajoute un utilisateur, false : on modifie un utilisateur
			userToArchive: "",				// id de l'utilisateur a archiver
		}
	},
	components: {
		PopupNotification,
		ArchivesTab
	},
	computed: {
		...mapGetters({
            user: 'getUserData',
			empty_resume: 'getEmptyResume'
        })
	},
	mounted() {
		// Get all users on mounted
		this.getAll();
		this.isUserSuperAdmin = this.user.role == "Super Admin";
		console.log(this.isUserSuperAdmin)
		console.log(this.user)
	},
	methods: {
		...mapActions(['updateResumeFormData']),
		/**
		 * Toggle the label of an input field
		 * @param {Number} index - The index of the input field
		*/
		toggleLabel(index) {
			const inputElement = document.querySelector(`input[name="${this.formFields[index].name}"]`);
			const hasText = inputElement.value.trim() !== '';

			if (!hasText)
				this.formFields[index].isLabelHidden = !this.formFields[index].isLabelHidden;
			if (hasText && !this.formFields[index].isLabelHidden)
				this.formFields[index].isLabelHidden = true;
		},
		/**
		 * Fetch all users from the database
		*/
		getAll() {
			axios.get(`${BACK_URL}/api/users`, {headers: {'Authorization': `${this.user.id}` }})
			.then(response => {
				this.allUsers = response.data.users;

				// Tri des utilisateurs par date de modification => Du plus récent au plus ancien
				this.allUsers.sort((a, b) => new Date(b.lastResumeUpdate) - new Date(a.lastResumeUpdate));

				for (let i = 0; i < this.allUsers.length; i++) {
					const creationDate = new Date(this.allUsers[i].creationDate);
					const lastResumeUpdate = new Date(this.allUsers[i].lastResumeUpdate);

					// Formatter la date
					const optionsDate = { year: '2-digit', month: '2-digit', day: '2-digit' };
					// Formatter l'heure
					const optionsTime = { hour: '2-digit', minute: '2-digit' };

					this.allUsers[i].creationDate = creationDate.toLocaleDateString('fr-FR', optionsDate) + " à " + creationDate.toLocaleTimeString('fr-FR', optionsTime).replace(':', 'h');
					this.allUsers[i].lastResumeUpdate = lastResumeUpdate.toLocaleDateString('fr-FR', optionsDate) + " à " + lastResumeUpdate.toLocaleTimeString('fr-FR', optionsTime).replace(':', 'h');
				}

				this.userList = this.allUsers;
			})
			.catch((error) => {
				console.error('Error fetching users:', error)
				this.$refs.notification.addNotification("Une erreur est survenue lors du chargement des utilisateurs. Veuillez recharger la page.", "error", 3000);
			});
		},
		/**
		 * Filter users by search input and selected filter
		*/
		filter_users() {
			try {
				this.userList = [];

				this.allUsers.forEach(user => {
					if ((user.everwinNum.includes(this.searchInput) || user.firstName.includes(this.searchInput) || 
						user.role.includes(this.searchInput)) &&
						(this.selectedFilter === "Tous" || user.role.toLowerCase() === this.selectedFilter.toLowerCase())) {
							this.userList.push(user);
						}
					}
				);

				if (this.selectedSort === "Màj récente") {
					this.userList.sort((a, b) => new Date(b.lastResumeUpdate) - new Date(a.lastResumeUpdate));
				}
				else if (this.selectedSort === "Màj ancienne") {
					this.userList.sort((a, b) => new Date(a.lastResumeUpdate) - new Date(b.lastResumeUpdate)).reverse();
				}
				else if (this.selectedSort === "Numéro Everwin") {
					this.userList.sort((a, b) => {
						return parseInt(a.everwinNum.replace("CDT", "").trim()) - (parseInt(b.everwinNum.replace("CDT", "").trim()))
					});
				}
				else if (this.selectedSort === "Prénom") {
					this.userList.sort((a, b) => a.firstName.localeCompare(b.firstName));
				}
				else if (this.selectedSort === "Role") {
					this.userList.sort((a, b) => a.role.localeCompare(b.role));
				}
				else if (this.selectedSort === "Statut") {
					const ordre_personnalise = { "Approuvé": 0, "Validé": 1, "En cours": 2 };
					this.userList.sort((a, b) => ordre_personnalise[a.resumeStatus] - ordre_personnalise[b.resumeStatus]);
				}
			} catch (e) {console.log(e)}	
		},
		/**
		 * Change the status of a resume
		 * @param {Object} user - The user object
		 * @param {Number} index - The index of the user in the list
		*/
		changeResumeStatus(user, index) {
			this.selectedUser = user;

			let payload = {
				isEditingElse: false,
				userId: this.selectedUser.userId,
			}

			axios.post(`${BACK_URL}/api/validate_resume`, payload)
			.then((response) => {
				if (response.status == 200) {
					this.userList.at(index).resumeStatus = convertResumeStatus(response.data)
					this.$refs.notification.addNotification("Le CV à bien été validé !", "success", 3000);
				}
			})
			.catch((error) => {
				this.$refs.notification.addNotification(error.response.data.message, "error", 3000);
			})
		},
		/**
		 * Modify an user
		 * @param {Object} user - The user object
		*/
		modifyUser(user) {
			this.selectedUser = user;
			this.panel = 'Modifier Utilisateur'
			this.formFields[0].value = user.firstName;
			this.formFields[1].value = user.everwinNum;
			for (let i = 0; i < 2; i++) {
				this.formFields[i].isLabelHidden = true;
			}
			this.addUser = false;
			this.isModalVisible = true;
		},
		/**
		 * Update a user
		*/
		updateUser() {
			let form_data = {
				userId: this.selectedUser.userId,
				firstName: this.formFields[0].value,
				everwinNum: this.formFields[1].value,
				role: this.role
			};
			try {
				if (this.role == "") {
					this.$refs.notification.addNotification("Vous devez spécifier un role", "error", 3000);
					return
				}
				axios.post(`${BACK_URL}/api/modify_user`, form_data, {headers: {'Authorization': `${this.user.id}` }})
				.then((response) => {
					this.$refs.notification.addNotification(response.data.message, "success", 3000);
					this.getAll();
				})
				.catch((error) => {
					this.$refs.notification.addNotification(error.response.data.message, "error", 3000);
				})
			}
			catch (error) {
				console.error('Error submitting form:', error);
				this.$refs.notification.addNotification(error, "error", 3000);
			}
		},
		/**
		 * Add a user
		*/
		createUser() {
			console.log(this.empty_resume)
			let form_data = {
				firstName: this.formFields[0].value,
				everwinNum: this.formFields[1].value,
				email: this.formFields[2].value,
				role: this.role,
				resume: this.empty_resume
			};
			try {
				if (form_data.role == "Candidat" && !form_data.everwinNum.startsWith("CDT"))
					this.$refs.notification.addNotification("Le numéro Everwin d'un candidat doit commencer par 'CDT'", "error", 3000);
				else {
					this.$refs.notification.addNotification("Création de l'utilisateur en cours...", "info", 3000);
					axios.post(`${BACK_URL}/api/create_user`, form_data, {headers: {'Authorization': `${this.user.id}` }})
					.then((response) => {
						console.log(response)
						this.formFields[0].value = "";
						this.formFields[0].isLabelHidden = false;
						this.formFields[1].value = "";
						this.formFields[1].isLabelHidden = false;
						this.formFields[2].value = "";
						this.formFields[2].isLabelHidden = false;
						this.getAll();
						this.$refs.notification.addNotification(response.data.message, "success", 3000);
					})
					.catch((error) => {
						console.log(error);
						this.$refs.notification.addNotification("Erreur lors de l'ajout de l'utilisateur: " + error.response.data.message, "error", 3000);
					})
				}
				
			}
			catch (error) {
				console.error('Error submitting form:', error);
				this.$refs.notification.addNotification(error.data.message, "error", 3000);
			}
		},
		/**
		 * Send an email to the user with his login credentials
		*/
		sendAccountMail() {
			let form_data = {
				userId: this.selectedUser.userId,
				email: document.getElementById("sendMailInput").value,
			};

			this.$refs.notification.addNotification("Envoi du mail en cours...", "info", 3000);
			axios.post(`${BACK_URL}/api/send_account_user`, form_data, {headers: {'Authorization': `${this.user.id}` }})
			.then((response) => {
				this.$refs.notification.addNotification(response.data.message, "success", 3000);
			}).catch((error) => {
				console.log(error);
				this.$refs.notification.addNotification("Erreur lors de l'envoi de l'email.", "error", 3000);
			})
		},
		/**
		 * Cancel the current action and close the modal
		*/
		cancel() {
			this.isModalVisible = false;
			this.isArchiveModalVisible = false;
			this.isRenewPassordModalVisible = false;
			this.isSendMailModalVisible = false;
			this.selectedUser = {userId: "", firstname: "", everwinNum: "", role: ""};
			this.formFields[0].value = "";
			this.formFields[1].value = "";
			for (let i = 0; i < 2; i++) {
				this.formFields[i].isLabelHidden = false;
			}
			this.userToDelete = "";
			document.getElementById("resetPasswordInput") ? document.getElementById("resetPasswordInput").value = "" : null;
		},
		/**
		 * Archive an user
		 * @param {String} userId - The id of the user to archive
		*/
		archiveUser(userId) {
			this.userToArchive = userId;
			this.isArchiveModalVisible = true;
		},
		/**
		 * Archive an user
		*/
		confirmArchiveUser() {
			axios.post(`${BACK_URL}/api/archive_user`, {userId: this.userToArchive, adminId: this.user.id})
			.then(() => {
				this.getAll();
				this.$refs.notification.addNotification("Utilisateur archivé.", "success", 3000);
			}).catch((error) => {
				console.log(error);
				this.$refs.notification.addNotification(error.response.data.message, "error", 3000);
			})
			this.isArchiveModalVisible = false;
		},
		/**
		 * Reset the password of an user
		*/
		resetPassword() {
			var mail = document.getElementById("resetPasswordInput").value;
			this.$refs.notification.addNotification("Envoie de l'email...", 'info', 3000);
			axios.post(`${BACK_URL}/api/request_reset_password`, {userId: `${this.selectedUser.userId}`, email: mail})
			.then(() => {
				this.$refs.notification.addNotification("Email de réinitialisation envoyé.", "success", 3000);
			}).catch((error) => {
				console.log(error);
				this.$refs.notification.addNotification("Erreur lors de l'envoi de l'email.", "error", 3000);
			})
		},
		/**
		 * Download the resume of an user
		 * @param {String} userId - The id of the user
		 * @param {String} firstName - The first name of the user
		 * @param {String} everwinNum - The everwin number of the user
		*/
		downloadResume(userId, firstName, everwinNum) {
			this.$refs.notification.addNotification("Votre téléchargement va commencer...", 'info', 5000);
			axios.post(`${BACK_URL}/api/download_resume`, {
				userId: userId,
				initiator: localStorage.me
			}, {
				responseType: 'blob'
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				if (response.data.type == "application/zip")
					link.setAttribute('download', 'CV ' +firstName + ' ' + everwinNum + '.zip');
				else
				link.setAttribute('download', 'CV ' +firstName + ' ' + everwinNum + '.pdf');
				document.body.appendChild(link);
				link.click();
			}).catch(async (error) => {
				const err = JSON.parse(await error.response.data.text());
				console.error(error, err);
				this.$refs.notification.addNotification("Erreur lors du telechargement du CV. " + err.message, "error", 3000);
			});
		},
		/**
		 * Modify the CV of an user as Admin and redirect to the generator
		 * @param {Object} user - The user object
		*/
		modifyUserCV(user) {
			axios.get(`${BACK_URL}/api/data`, { params: { userId: user.userId }})
				.then(response => {
					if (response.status != 204) {
						this.updateResumeFormData(response.data.data)
					}
					else
						this.updateResumeFormData(this.empty_resume)
					this.$router.push({ path: `/`, query: { as: user.userId } });
				})
				.catch((error) => {
					console.error('Error fetching data:', error)
					this.$refs.notification.addNotification("Erreur lors de la récupération des données.", "error", 3000);
				});
		},
	}
}
</script>

<style>

#users {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

#users td, #users th {
  padding: 8px;
}

#users tr:nth-child(even){background-color: #f2f2f2;}

/* #users tr:hover {background-color: #ddd;} */

#users th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f9a825;
  color: white;
}

.title {
	justify-content: center;
	padding-left: 12.5%;
	padding-top: 3rem;
	width: 75%;
	text-align: left;
	font-size: 2rem;
	font-weight: bold;
	text-transform: uppercase;
}

hr {
    border: 1px solid #f9a825;
    margin: auto;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
}

td.btn_manage_users {
    display: flex;
    justify-content: space-evenly;
}

button.btn_user {
    background: none;
    border: none;
    cursor: pointer;
}

button.btn_user:hover {
    cursor: pointer;
}

button.btn_user:hover img {
    filter: brightness(0) saturate(100%) invert(65%) sepia(94%) saturate(361%) hue-rotate(360deg) brightness(103%) contrast(94%);
}

tr.text:hover td {
    
	background: #9d9d9d !important;
	cursor: pointer;
}

</style>
